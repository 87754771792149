.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start;
  align-items: center;
}

.flex-row-pure {
  display: flex;
  flex-direction: row !important;
  box-sizing: border-box !important;
}

.flex-row-wrap {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  align-items: flex-start;
}

.flex-column-pure {
  display: flex !important;
  flex-direction: column !important;
}

$fxFlex: (
  0: 0,
  2: 2%,
  3: 3%,
  4: 4%,
  5: 5%,
  8: 8%,
  10: 10%,
  12: 12%,
  13: 13%,
  14: 14%,
  15: 15%,
  19: 19%,
  20: 20%,
  24: 24%,
  25: 25%,
  30: 30%,
  32: 32%,
  33: 33%,
  35: 35%,
  40: 40%,
  42: 42%,
  45: 45%,
  48: 48%,
  49: 49%,
  50: 50%,
  51: 51%,
  55: 55%,
  58: 58%,
  59: 59%,
  60: 60%,
  65: 65%,
  66: 66%,
  70: 70%,
  75: 75%,
  80: 80%,
  90: 90%,
  95: 95%,
  100: 100%,
);
@each $name, $value in $fxFlex {
  @if $value == 0 {
    .flex-basis {
      flex: 1 1 0;
    }
  } @else {
    .flex-basis-#{$name} {
      flex: 1 1 $value;
    }
  }
  .flex-basis-row-#{$name} {
    max-width: $value;
    box-sizing: border-box !important;
  }
  .flex-basis-column-#{$name} {
    max-height: $value;
    box-sizing: border-box !important;
  }
}

.flex-basis-grow {
  flex: 1 1 100%;
}

.flex-basis-auto {
  flex-basis: 100%;
}

$fxLayoutGap: (
  0: 0px,
  2: 2px,
  4: 4px,
  6: 6px,
  8: 8px,
  10: 10px,
  16: 16px,
  20: 20px,
  24: 24px,
  30: 30px,
  36: 36px,
);

@each $name, $value in $fxLayoutGap {
  // flex-direction: row
  .flex-item-margin-right-#{$name} {
    margin-right: $value;
  }
  // flex-direction: column
  .flex-item-margin-bottom-#{$name} {
    margin-bottom: $value;
  }
}

@each $name, $value in $fxLayoutGap {
  // flex-direction: row
  .row-gap-#{$name} {
    gap: $value;
  }
  // flex-direction: column
  .column-gap-#{$name} {
    gap: $value;
  }
}

$fxLayoutOffset: (
  0: 0,
  2: 2%,
  4: 4%,
  5: 5%,
  8: 8%,
  16: 16%,
  24: 24%,
  36: 36%,
  40: 40%,
);
@each $offset, $value in $fxLayoutOffset {
  .flex-offset-#{$offset} {
    margin-left: #{$value};
  }
}

// flex-direction: row
.flex-item-margin-right {
  margin-right: 16px;
}

// flex-direction: column
.flex-item-margin-bottom {
  margin-bottom: 16px;
}

$flexAlignOptions: (
  start: flex-start,
  flex-start: flex-start,
  center: center,
  end: flex-end,
  flex-end: flex-end,
  space-between: space-between,
  space-around: space-around,
  stretch: stretch,
);

@each $name, $value in $flexAlignOptions {
  .flex-justify-content-#{$name} {
    justify-content: $value;
  }

  .flex-align-items-#{$name} {
    align-items: $value;
  }

  .flex-align-content-#{$name} {
    align-content: $value;
  }
  .align-self-#{$name} {
    align-self: $value;
  }
}

.flex-align-items-base-line {
  align-items: baseline;
  align-content: stretch;
}

.flex-grow {
  flex-grow: 1;
  flex-basis: 0;
}
