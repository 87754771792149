.ng-select-required {
  color: #f44336;
  font-size: 11px;
  display: block;
  margin-top: -17px;
}

ng-select {
  margin-top: -10px;

  .ng-select-container .ng-value-container {
    margin-top: 6px;
  }

  &.ng-invalid.ng-touched {
    .ng-arrow {
      color: #f44336;
    }

    .ng-select-container {
      .ng-value-container .ng-placeholder {
        color: #f44336;
      }

      &:after {
        border-bottom: thin solid #f44336;
      }
    }
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #03a9f4;
  border-radius: 22px;
  color: #fff;
  padding: 0 3px;
}
