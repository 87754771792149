.mat-list-item-content-clicked {
  background-color: transparent;
}
.mat-list-item-content-not-clicked {
  background-color: rgba(182, 218, 255, 0.67);
}
.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px;
  position: relative;
  height: inherit;
}
.mat-list-base {
  padding-top: 0 !important;
}
.notification-list {
  min-width: 240px;
}
.notification-list .notific-item {
  display: flex;
  padding: 1px 10px;
}
.notification-list .notific-item a {
  outline: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.notification-list .notific-item .message {
  font-size: 0.875rem !important;
  width: 225px;
  word-break: break-word;
}
.notification-list .notific-item .time {
  font-size: 0.75rem !important;
}
.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content {
  padding: 0 !important;
  margin: 10px;
  border-radius: 10px;
}
.notif-icon {
  margin: 5px;
}
