.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

ngx-material-timepicker-toggle mat-icon {
  font-size: 18px;
  color: #757575;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.timepicker__header {
  background-color: #31404b !important;
}

.clock-face__number > span.active {
  background-color: #03a9fa !important;
}
