// Page loader Topbar
@import '../../vendor/pace/pace-theme-min.css';

// Main Variables File
@import 'scss/variables';

@import 'scss/colors';

// Open _themes.scss and remove unnecessary theme files
@import 'scss/themes/themes';
@import 'scss/utilities/utilities';
@import 'scss/components/components';
@import 'scss/palette';

// Open _main.scss and keep files which are required for your current layout.
@import 'scss/main/main';

// Open _views.scss and remove unnecessary view related files
@import 'scss/views/views';
