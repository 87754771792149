$mat-primary: map-get($material-colors, datategy);

.ngx-datatable.material {
  .datatable-header,
  .datatable-header-cell {
    background: map-get($mat-primary, 500) !important;
    .datatable-header-cell {
      color: white !important;
    }
  }
  &.single-selection,
  &.multi-click-selection {
    .datatable-body-row.active {
      &,
      &:hover {
        .datatable-row-group {
          background-color: aliceblue !important;
          color: #fff !important;
        }
      }
    }
  }
  .datatable-body {
    .datatable-scroll {
      cursor: pointer;
    }
  }
  &.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: normal !important;
    text-align: center !important;
    vertical-align: middle !important;
  }
  &.fixed-row .datatable-body-row .datatable-body-cell {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: normal !important;
    text-align: center !important;
    vertical-align: middle !important;
  }

  .datatable-tree-button {
    cursor: pointer;
    border: none;
    background-color: transparent;
  }

  .datatable-tree-button[disabled] {
    cursor: not-allowed;
  }

  .ngx-datatable-text-cell-color-red {
    color: red !important;
  }
}
