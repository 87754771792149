.notification-number {
  position: absolute;
  top: 0;
  left: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}
