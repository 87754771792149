@use '@angular/material' as mat;
@use '../colors';
$mat-primary: map-get(colors.$material-colors, datategy);
$mat-accent: map-get(colors.$material-colors, light-blue);
$mat-warning: map-get(colors.$material-colors, red);

$egret-primary: mat.define-palette($mat-primary);
$egret-accent: mat.define-palette($mat-accent);
$egret-warn: mat.define-palette($mat-warning);

$egret-theme: mat.define-light-theme(
  (
    color: (
      primary: $egret-primary,
      accent: $egret-accent,
      warn: $egret-warn,
    ),
    density: 0,
  )
);

$primary-dark: darken(map-get($mat-primary, 500), 8%);

// Material theme is wrapped by a parent class
.egret-datategy {
  @include mat.all-component-themes($egret-theme);

  .mat-bg-primary,
  .topbar,
  .chats-wrap .conversations-hold .single-conversation.me .conversation-msg,
  .ngx-datatable .datatable-footer .datatable-pager .pager .pages.active a,
  .fileupload-drop-zone.dz-file-over,
  .toolbar-avatar.online > .status-dot,
  .cal-open-day-events {
    background: map-get($mat-primary, 500) !important;
    color: #ffffff !important;
  }
  .mat-color-primary,
  .list-item-active,
  .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
  .sidenav-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
  .sidenav-hold .sidenav li.open > a > span {
    color: map-get($mat-primary, 500) !important;
  }
  .sidebar-compact ul.submenu,
  .default-bg {
    background: #ffffff !important;
  }
  .default-light-bg {
    background: #fafafa !important;
  }

  .mat-bg-warn {
    background-color: #f44336;
    color: white;
  }
  .mat-color-accent {
    color: map-get($mat-accent, 500);
  }
  .mat-color-warn {
    color: #f44336;
  }
  .mat-color-default {
    color: rgba(0, 0, 0, 0.87);
  }

  .fill-color-accent {
    color: map-get($mat-accent, 500);
  }

  .fill-color-warn {
    color: #f44336;
  }
  @media (max-width: 1279px) {
    .default-bg-mobile {
      background: #ffffff;
    }
  }
}
