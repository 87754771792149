.home-section {
  padding: 80px 0;
}
.scrollable {
  position: relative;
  width: 100%;
  height: 100vh;
}
.section-demos {
  position: relative;
  background: #ffffff;
  .demo-box-wrap {
    padding: 2rem;
  }
  .demo-box {
    position: relative;
    border-radius: 8px;
    transition: 0.2s all ease-in-out;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    &:hover {
      box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2);
      .caption {
        opacity: 1;
        transform: translateY(-10px);
      }
    }
    .caption {
      opacity: 0;
      background: #ffffff;
      padding: 1rem;
      position: absolute;
      z-index: 99;
      top: -20px;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 8px;
      width: 220px;
      transition: 0.2s all ease-in-out;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
    h3 {
      margin: 0 0 1rem;
      font-size: 22px;
      font-weight: 300;
    }
    img {
      width: 100%;
      height: auto;
      float: left;
    }
    .screenshot {
      overflow: hidden;
      border-radius: 8px;
      margin-bottom: 2rem;
      // min-height: 180px;
      cursor: pointer;
    }
  }
}
