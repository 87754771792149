$lenghts: (
  0: 0px,
  // 10: 10px,
  // 20: 20px,
  // 30: 30px,
  40: 40px,
  // 50: 50px,
  // 60: 60px,
  // 70: 70px,
  80: 80px,
  // 90: 90px,
  // 100: 100px,
  // 110: 110px,
  120: 120px,
  // 130: 130px,
  160: 160px,
  200: 200px,
  240: 240px,
  260: 260px,
  270: 270px,
  275: 275px,
  283: 283px,
  300: 300px,
  450: 450px,
  496: 496px,
);

@each $name, $value in $lenghts {
  .height-#{$name}px {
    height: $value;
  }
  .width-#{$name}px {
    width: $value;
  }
}

.height-100 {
  height: 100%;
}
.width-100 {
  width: 100%;
}
.width-50 {
  width: 50%;
}

// View height classes
.height-100vh {
  height: 100vh;
}
.height-100vh-70px {
  height: calc(100vh - 70px);
}
.height-100vh-80px {
  height: calc(100vh - 80px);
}
.height-100vh-280px {
  height: calc(100vh - 280px);
}
