/*
 *
 *   EGRET - Angular Material Design Admin Template
 *
 *
*/

// Third party style files
// Sidebar Scroll
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
// Flag icon
@import '~flag-icons/css/flag-icons.min.css';

// Data Table
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

// Drag and Drop
@import '~dragula/dist/dragula.css';

// Calendar
@import '~angular-calendar/css/angular-calendar.css';

// Page loader Topbar
@import '../../vendor/pace/pace-theme-min.css';

// Main Variables File
@import 'scss/variables';

@import 'scss/mixins/mixins';
@import 'scss/colors';

@import 'scss/animations';

// Open _themes.scss and remove unnecessary theme files
@import 'scss/themes/themes';

@import 'scss/utilities/utilities';
@import 'scss/components/components';
@import 'scss/palette';

@import 'scss/page-layouts';

// Open _main.scss and keep files which are required for your current layout.
@import 'scss/main/main';

// Open _views.scss and remove unnecessary view related files
@import 'scss/views/views';

// leaflet
@import '~leaflet/dist/leaflet.css';
// font awesome
@import '~font-awesome/css/font-awesome.css';

//Angular 2 MultiSelect Dropdown
@import '~angular2-multiselect-dropdown/themes/default.theme.css';

// Angular ng-select
@import '~@ng-select/ng-select/themes/material.theme.css';
